import styled, { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --start-bg: ${(props) => props.startColor || "#edf4fc"};
    --end-bg: ${(props) => props.endColor || "#fef5fa"};
    --text-color: ${(props) => props.text || "#fff"};
  }

  #root, .App {
    height: 100%;
  }
`;

export const Container = styled.div`
  height: 100%;
  position: ${(props) => props.level === 1 ? 'absolute' : 'relative'};
  z-index: ${(props) => props.level || 0};

  ${(props) =>
    props.level === 1 &&
    css`
      top: 0;
      left: 0;
      right: 0;
      max-width: 1280px;
      margin: auto;
    `}

  @media (max-width: 767px) {
    background: linear-gradient(
      243.18deg,
      var(--start-bg) 0%,
      var(--end-bg) 100%
    );
    padding: 10px 20px;
  }

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const LeftContainer = styled.div`
  position: relative;
  z-index: 0;
  color: var(--text-color);
  width: 100%;

  @media (max-width: 767px) {
    margin-top: 35%;
  }

  @media (max-width: 767px) and (min-height: 700px) {
    margin-top: 50%;
  }

  @media (min-width: 768px) {
    width: 55%;
    padding-right: 8%;
    display: flex;
    align-items: center;
  }

  @media (min-width: 1200px) {
    width: 50%;
  }
`;

export const RightContainer = styled.div`
  width: 100%;

  @media (max-width: 767px) {
    position: absolute;
    bottom: 80px;
    width: 100%;
    left: 0;
    padding: 0 20px;
  }

  @media (min-width: 768px) {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 768px) and (max-width: 920px) {
    padding-left: 5%;
  }

  @media (min-width: 1200px) {
    width: 50%;
  }
`;

export const SkewContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: 120%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }

  @media (min-width: 768px) and (max-width: 920px) {
    width: 140%;
  }
`;

export const SkewBox = styled.div`
  background: linear-gradient(243.18deg, var(--start-bg), var(--end-bg));
  width: 100%;
  height: 100%;
  transform: skew(-15deg) translateX(-16.5%);

  @media (min-width: 768px) and (max-width: 920px) {
    transform: skew(-15deg) translateX(-31%);
  }
`;

export const DetailsLeft = styled.div`
  padding-left: 10%;

  @media (max-width: 767px) {
    text-align: center;
    padding-left: 0;
  }
`;
export const DetailsRight = styled.div`
  text-align: center;
`;

export const Terms = styled.p`
  position: absolute;
  bottom: 15px;
  font-size: 10px;
  color: var(--text-color);
  left: 9%;

  @media (max-width: 767px) {
    position: fixed;
    left: 0;
    width: 100%;
    text-align: center;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const ExternalLink = styled.a`
  display: inline-block;
`;

export const Image = styled.img`
  max-width: 100px;
  max-height: 30px;
  position: fixed;
  top: 20px;
  left: 20px;

  @media (min-width: 768px) {
    position: static;
    max-width: 250px;
    max-height: 75px;
  }

  @media (orientation: landscape) and (max-width: 849px) {
    max-width: 100px;
    max-height: 30px;
  }
`;

export const Title = styled.h1`
  font-family: "CogniCue", sans-serif;
  font-stretch: condensed;
  font-weight: 700;
  margin: 15px 0 0;
  font-size: 32px;
  color: var(--text-color);
`;

export const SubTitle = styled.h2`
  color: var(--text-color);
  font-weight: 400;
  font-size: 1rem;
  margin: 10px 0 0;
`;

export const UserTitle = styled.h3`
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 300;
  margin: 0;

  @media (min-width: 768px) {
    color: #000;
  }
`;

export const UserSubTitle = styled.h4`
  color: var(--text-color);
  font-size: 1.5rem;
  font-weight: 300;
  margin: 10px 0 0;

  @media (min-width: 768px) {
    color: #000;
  }
`;

export const UserButton = styled.a`
  display: block;
  color: #fff;
  background-color: ${(props) => props.bgColor || "#04DBEC"};
  font-size: 1rem;
  text-decoration: none;
  font-weight: 700;
  padding: 11px 60px;
  border-radius: 10px;
  line-height: 1;
  max-width: 400px;
  margin: 30px auto 0;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
  border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;

  @media (min-width: 768px) {
    display: inline-block;
    border-radius: 25px;
  }

  &:hover {
    color: ${(props) => props.bgColor || "#04DBEC"};
    border-color: ${(props) => props.bgColor || "#04DBEC"};
    background-color: #fff;
    transition: color 0.15s ease-in-out 0s,
      background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
  }
`;
