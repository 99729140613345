import Request, { API } from "./request.api";
import { Branding } from "../constant";
const applicationInterview = "application=INTERVIEW";

export const fetchBrandByAccount = async (accountId) => {
  try {
    const { data } = await Request.get(
      `${API.BRANDING}?${applicationInterview}`,
      {
        headers: {
          "X-ACCOUNT-ID": accountId,
        },
      }
    );

    return Promise.resolve(data);
  } catch {
    return Promise.resolve({
      ...Branding,
      default: true,
    });
  }
};

const fetchBrandData = async (hostname) => {
  try {
    const { data } = await Request.get(
      `${API.BRANDING}?${applicationInterview}&hostname=${hostname}`,
      {}
    );

    return Promise.resolve(data);
  } catch {
    return Promise.resolve({
      ...Branding,
      default: true,
    });
  }
};

export default fetchBrandData;
