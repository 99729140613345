import { STATUS } from "./constant";
import { getInterviewStatus } from "./services/localStorageService";

export const queryParams = (key) => {
  const query = new URLSearchParams(window.location.search);
  return query.get(key);
};

export const omit = (obj) => {
  return Object.keys(obj)
    .filter((name) => Boolean(obj[name]))
    .reduce((acc, name) => ({ ...acc, [name]: obj[name] }), {});
};

export function isAlreadyTaken(status) {
  if (!status) {
    return false;
  }

  const { STARTED } = STATUS;
  const localStatus = getInterviewStatus();
  return localStatus === STARTED || status === STARTED;
}
