import React, { lazy, Suspense, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useAtomValue } from "jotai";
import { loaderAtom } from "../atoms/loader.atom";
import Loader from "../components/Loader";
import Home from "../pages/home";
import { HelmetProvider } from "react-helmet-async";

const Account = lazy(() => import("../pages/account"));
const CandidateInterview = lazy(() => import("../pages/candidate-interview"));
const Exit = lazy(() => import("../pages/exit"));
const UserError = lazy(() => import("../pages/user-error"));
const SystemCheck = lazy(() => import("../pages/system-check"));
const Interview = lazy(() => import("../pages/interview"));

const optionsConfig = {
  // accountID: "d3558833-b2e2-4d30-b7a3-b330c27adc1a",
  audioRecord: true,
  videoRecord: true,
  interviewID: "MR001",
  candidateInterviewID: null,
  sessionCreate: false,
  alertError: (msg) =>
    toast.error(msg, {
      id: "alterError",
    }),
  faceDetect: () => toast.dismiss("alterError"),
};

function App() {
  const loaderState = useAtomValue(loaderAtom);
  const facs = useRef(new window.CogniCue(optionsConfig));

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/system-check"
            element={
              <Suspense fallback={<>...</>}>
                <SystemCheck facs={facs.current} />
              </Suspense>
            }
          />
          <Route
            path="/interview"
            element={
              <Suspense fallback={<>...</>}>
                <Interview facs={facs.current} />
              </Suspense>
            }
          />
          <Route
            path="/exit"
            element={
              <Suspense fallback={<>...</>}>
                <Exit facs={facs.current} />
              </Suspense>
            }
          />
          <Route
            path="/user-error"
            element={
              <Suspense fallback={<>...</>}>
                <UserError />
              </Suspense>
            }
          />
          <Route
            path="/:account_id/:candidate_interview_id"
            element={
              <Suspense fallback={<>...</>}>
                <CandidateInterview facs={facs.current} />
              </Suspense>
            }
          />
          <Route
            path="/:account_id"
            element={
              <Suspense fallback={<>...</>}>
                <Account />
              </Suspense>
            }
          />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
        {loaderState && <Loader />}
        <Toaster />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
