import { useAtom, useSetAtom } from "jotai";
import { candidateAtom } from "../../atoms/candidate.atom";
import { loaderAtom } from "../../atoms/loader.atom";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchDummy } from "../../services/dummy.api";
import fetchBrandData from "../../services/fetch-brand-data.service";
import {
  isWhiteListed,
  BUTTON_TEXT,
  TITLE_TEXT,
  URL_TEXT,
} from "../../constant";
import { CandidateInterview } from "../../constant";
import NewScreen from "../../components/new-screen";
import { GlobalStyle } from "../../components/new-screen/new-screen.style";
import metaTags from "../../utils/metaTags";

const Home = () => {
  const [candidate, setCandidate] = useAtom(candidateAtom);
  const [searchParams] = useSearchParams();
  const setIsLoading = useSetAtom(loaderAtom);

  useEffect(() => {
    const HOST_NAME = window.location.hostname;
    setIsLoading(true);
    if (isWhiteListed(HOST_NAME)) {
      fetchDummy().then((data) => {
        setCandidate(data);
        setIsLoading(false);
        metaTags({
          title: data?.branding?.brand_title,
          description: data?.branding?.brand_description,
          icon: data?.branding?.brand_ico,
          themeColor: data?.branding?.brand_bg_color,
        });
      });
    } else {
      fetchBrandData(HOST_NAME)
        .then((data) => {
          setCandidate({
            ...CandidateInterview,
            branding: data,
            buttonText: data.buttonText ?? BUTTON_TEXT.home,
            titleText: data.titleText ?? TITLE_TEXT.home,
          });
          setIsLoading(false);
          metaTags({
            title: data?.brand_title,
            description: data?.brand_description,
            icon: data?.brand_ico,
            themeColor: data?.brand_bg_color,
          });
        })
        .catch(() => {
          fetchDummy().then((data) => {
            data.branding.brand_url = URL_TEXT.home;
            setCandidate(data);
            setIsLoading(false);
            metaTags({
              title: data?.branding?.brand_title,
              description: data?.branding?.brand_description,
              icon: data?.branding?.brand_ico,
              themeColor: data?.branding?.brand_bg_color,
            });
          });
        });
    }
  }, [searchParams, setCandidate, setIsLoading]);

  return (
    <>
    <GlobalStyle
      startColor={candidate?.branding?.brand_bg_color}
      endColor={candidate?.branding?.brand_gd_color}
      text={candidate?.branding?.brand_text_color}
    />
    <NewScreen
      branding={candidate?.branding}
      defaultMessage={candidate?.default_msg}
      title={candidate?.titleText}
      candidate={candidate?.candidate}
      buttonDetails={{
        text: candidate?.buttonText,
        url: isWhiteListed(window.location.hostname)
          ? candidate?.branding?.interview_url
          : candidate?.branding?.brand_url,
      }}
    />
    </>
  );
};

export default Home;
