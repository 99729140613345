import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import range from "python-range";
import { queryParams } from "../helper";
import * as Constant from "../constant";

export const API = {
  CIQ: "/interview/candidate-interview-question/",
  CI: "/interview/candidate-interview/",
  TR: "/token/refresh/",
  TC: '/token/generate/',
  SSO: '/interview/candidate-interview/sso/',
  BRANDING: '/account/branding/',
  GENERATE_OTP: '/business/candidate-interview/otp/generate',
	VERIFY_OTP: '/business/candidate-interview/otp/verify'
};

export const API_BRANDING = '/account/branding/';

export const baseURL = process.env.REACT_APP_BASE_URL;

export const Request = axios.create({
  baseURL: baseURL
});

const refreshAuthLogic = (failedRequest) =>
  axios
    .post(baseURL + API.TR, {
      refresh: localStorage.getItem(Constant.REFRESH_TOKEN),
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem(
        Constant.ACCESS_TOKEN,
        tokenRefreshResponse.data.access
      );
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.access;
      return Promise.resolve();
    });

createAuthRefreshInterceptor(Request, refreshAuthLogic);

const resendDataLogic = (failedRequest) =>
  new Promise((r) => setTimeout(r, 2000)).then(() => {
    if (failedRequest.request.responseURL.includes(API.CIQ)) {
      return Promise.resolve();
    }
  });

const resendStatusCode = Array.from(range(404, 599));
resendStatusCode.push(...[400, 402]);

createAuthRefreshInterceptor(Request, resendDataLogic, {
  statusCodes: resendStatusCode,
});

Request.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    Object.assign(config.headers, {
      "Content-Type": `multipart/form-data; boundary=${config.data._boundary}`,
    });
  }
  Object.assign(config.headers, {
    Authorization:
      "Bearer " +
      (localStorage.getItem(Constant.ACCESS_TOKEN) ||
        queryParams(Constant.ACCESS)),
  });
  return config;
});

export default Request;
