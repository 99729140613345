import * as Constant from "../constant";

export const fetchDummy = () => {
  return new Promise((resolve) => {
    const data = Constant.CandidateInterview;
    data.branding.default = true;
    data.buttonText = Constant.BUTTON_TEXT.default;
    data.titleText = Constant.TITLE_TEXT.default;

    setTimeout(() => {
      resolve(data);
    }, 2000);
  });
}
