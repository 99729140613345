import styled, { keyframes, css } from "styled-components";

const loaderFrames = keyframes`
  100% { transform: rotate(360deg); }
`;

export const Overlay = styled.div`
  background-color: #000;
  opacity: 0.65;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 98;

  width: 100%;
  height: 100%;
`;

export const LoaderContainer = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  animation: ${loaderFrames} 2.5s infinite linear both;

  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  margin: auto;
`;

const LoaderDotsFrames = keyframes`
  80%, 100% { transform: rotate(360deg); }
`;

const LoaderDotsBeforeFrames = keyframes`
   50% {
    transform: scale(0.4);
  } 100%, 0% {
    transform: scale(1.0);
  }
`;

export const LoaderDots = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  animation: ${LoaderDotsFrames} 2s infinite ease-in-out both;

  &::before {
    content: "";
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: ${LoaderDotsBeforeFrames} 2s infinite ease-in-out both;
  }

  &:nth-child(1) {
    animation-delay: -1.1s;

    &::before {
      animation-delay: -1.1s;
    }
  }
  &:nth-child(2) {
    animation-delay: -1s;

    &::before {
      animation-delay: -1s;
    }
  }
  &:nth-child(3) {
    animation-delay: -0.9s;

    &::before {
      animation-delay: -0.9s;
    }
  }
  &:nth-child(4) {
    animation-delay: -0.8s;

    &::before {
      animation-delay: -0.8s;
    }
  }
  &:nth-child(5) {
    animation-delay: -0.7s;

    &::before {
      animation-delay: -0.7s;
    }
  }
  &:nth-child(6) {
    animation-delay: -0.6s;

    &::before {
      animation-delay: -0.6s;
    }
  }
`;

const pulseAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .6;
  }
`;

export const LoadingBox = styled.div`
  z-index: 99;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  transform: translateY(80px);
  width: 300px;
  height: 100px;
`;

export const LoadingBar = styled.div`
  position: relative;
  width: 300px;
  height: 10px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  transform: translateY(30px);
`;

export const LoadingStatus = styled.div`
  width: ${(props) => props.width || 0}%;
  animation: ${(props) =>
    props.width === 100
      ? `none`
      : css`
          ${pulseAnimation} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
        `};
  height: 100%;
  background-color: ${(props) => props.state};
  transition: width 100ms linear;
  position: absolute;
  top: 0;
  left: 0;
`;

export const LoadingValues = styled.div`
  transform: translateY(20px);
  color: white;
  display: flex;
  justify-content: space-between;
`;

export const ErrorButton = styled.button`
  transform: translateY(40px);
  margin: 0 auto;
  display: block;
  padding: 10px 18px;
  background: #fff;
  border: 0;
  border-radius: 8px;
  color: #000;
  cursor: pointer;
`;
