export const VI_STATUS = ["SCHEDULED", "STARTED"];

export const INTERVIEW_STATUS = "interview_status";
export const APP_URL = "APP_URL";
export const COMPLETED = "COMPLETED";

export const Branding = {
  brand_title: "Interview by CogniCue",
  brand_description: "Automated Interview System",
  brand_bg_color: "#000D21",
  brand_gd_color: "#000821",
  brand_hover_color: "#04dbec",
  brand_text_color: "#ffffff",
  brand_ico: "https://media.cognicue.in/icons/icon.svg",
  brand_url: "https://www.cognicue.in",
  brand_bg: "/assets/img/welcome-background.svg",
  brand_logo: "https://media.cognicue.in/logos/logo-black.svg",
  welcome_logo: "https://media.cognicue.in/logos/logo-white.svg",
  interview_url:
    "https://services.cognicue.in/interview/cognicue/?interview_mode__net=2&ref=app",
};

export const CandidateInterview = {
  candidate: {},
  interview: {},
  branding: Branding,
  default_msg: "Hey there,",
  interview_status: "COMPLETED",
  tnc_url: "https://www.cognicue.in/terms-of-use/",
};

export const BUTTON_TITLE = {
  "PRESS REC WHEN READY": "Record",
  "STOP BUTTON": "Stop",
  "PLAY VIDEO": "Preview",
  "PAUSE VIDEO": "Pause",
  "RECORD ANOTHER": "Record Again",
};

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";

export const ACCESS = "access";
export const REFRESH = "refresh";

export const CANDIDATE_ID = "candidate_id";
export const CANDIDATE_INTERVIEW_ID = "candidate_interview_id";
export const ACCOUNT_ID = "acc_id";

export const USER = "user";
export const INTERVIEW_DURATION = "interview_duration";
export const CANDIDATE_VIDEO = "candidate_video";

export const BRAND_DATA = "bData";

export const STATUS = {
  SCHEDULED: "SCHEDULED",
  STARTED: "STARTED",
  COMPLETED: "COMPLETED",
  PROCESSED: "PROCESSED",
  INCOMPLETE: "INCOMPLETE",
  BLOCKED: "BLOCKED",
};

export const RESOURCE_LOADED = "RESOURCE_LOADED";
export const RESOURCE_LOADING = "RESOURCE_LOADING";
export const BOTH = "BOTH";
export const LOADED = "LOADED";
export const SYSTEM_ERROR = "SYSTEM_ERROR";
export const MIC = "MIC";
export const CAM = "CAM";
export const AV_RECORDING = "AV_RECORDING";
export const AV_RECORDED = "AV_RECORDED";
export const SYSTEM_CHECKING = "SYSTEM_CHECKING";
export const SYSTEM_CHECKED = "SYSTEM_CHECKED";
export const FACE_CHECKS = "FACE_CHECKS";

export const BASE_INTERVIEWS = ["cognicue.in", "app-cognicue-in.pages.dev"];

export const isWhiteListed = (host) => host ? BASE_INTERVIEWS.some((url) => host.endsWith(url)) : false;

export const BUTTON_TEXT = {
  default: "Try For Free",
  home: "Home",
};

export const TITLE_TEXT = {
  default: "Register for an Interview?",
  home: "Register for an Interview?",
};

export const URL_TEXT = {
  default: "https://www.cognicue.in/",
  home: "https://services.cognicue.in/interview/cognicue/?interview_mode__net=2&ref=app",
};

export const VIDEO_RECORDING_LIMIT = 10000;
export const VIDEO_START_AFTER = 3000;
