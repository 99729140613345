import { useAtom } from "jotai";
import { useEffect, useState, useRef } from "react";
import { loaderProcessAtom } from "../../atoms/loader.atom";
import {
  Overlay,
  LoaderContainer,
  LoaderDots,
  LoadingBox,
  LoadingStatus,
  LoadingValues,
  LoadingBar,
  ErrorButton,
} from "./loader.style";
import { resources, INTERVAL_TIME } from "./Loader.constant";
import * as Sentry from "@sentry/react";

const Loader = () => {
  const [loaderProgress, setLoaderProgress] = useAtom(loaderProcessAtom);
  const [errorButton, setErrorButton] = useState(false);
  const [color, setColor] = useState("#16a34a");
  const [loadingValue, setLoadingValue] = useState(0);
  const [percentage, setPercentage] = useState(30);
  const [files, setFiles] = useState([]);
  const [currentTimeDisplay, setCurrentTimeDisplay] = useState(300);
  const timer = useRef(null);
  const timerSide = useRef(null);

  const errorButtonClick = () => {
    window.location.reload();
  };

  const cogniCueBuild = ({ detail }) => {
    if (detail?.error) {
      Sentry.captureException(detail);
      setErrorButton(true);
      setColor("#dc2626");
      return false;
    }

    // calculate number of files loaded
    const currentFiles = files;
    currentFiles.push(detail?.file);
    const inPercentage = (currentFiles.length / resources.length) * 100;
    setFiles(currentFiles);
    setLoadingValue(parseInt(inPercentage) - 2);
    const limit =
      parseInt(inPercentage) > 70 ? 100 : parseInt(inPercentage) + 20;
    setPercentage(limit);
  };

  const LoadingWidth = () => {
    // percentage of progress bar
    setLoadingValue((value) => {
      if (value >= percentage - 1) {
        clearInterval(timer.current);
        return percentage;
      }
      return value + 1;
    });
  };

  const LoadingTimer = () => {
    // countdown
    if (loadingValue >= 99) {
      clearInterval(timerSide.current);
      setCurrentTimeDisplay(0);
      setTimeout(() => {
        setLoaderProgress(false);
      }, 100);
    } else {
      setCurrentTimeDisplay((t) => t - 1);
    }
  };

  useEffect(() => {
    document.addEventListener("CogniCueBuild", cogniCueBuild);

    return () => {
      document.removeEventListener("CogniCueBuild", cogniCueBuild);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearInterval(timer.current);
    clearInterval(timerSide.current);
    timer.current = setInterval(LoadingWidth, INTERVAL_TIME);
    timerSide.current = setInterval(LoadingTimer, INTERVAL_TIME);

    return () => {
      clearInterval(timer.current);
      clearInterval(timerSide.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage]);

  useEffect(() => {
    if (currentTimeDisplay === 0 && loadingValue < 100) {
      setCurrentTimeDisplay((t) => t + 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeDisplay]);

  return (
    <>
      <Overlay />
      <LoaderContainer>
        {Array(6)
          .fill(1)
          .map((_, index) => (
            <LoaderDots key={`loader${index}`} />
          ))}
      </LoaderContainer>
      {loaderProgress ? (
        <LoadingBox>
          <LoadingValues>
            <span>{loadingValue}%</span>
            <span>
              {loadingValue === 100
                ? "loaded"
                : `preparing in ${currentTimeDisplay} seconds`}
            </span>
          </LoadingValues>
          <LoadingBar>
            <LoadingStatus width={loadingValue} state={color} />
          </LoadingBar>
          {errorButton ? (
            <ErrorButton onClick={errorButtonClick}>Reload Browser</ErrorButton>
          ) : null}
        </LoadingBox>
      ) : null}
    </>
  );
};

export default Loader;
