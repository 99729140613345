import { RESOURCE_LOADED, INTERVIEW_STATUS } from "../constant"

export function getLocalResourceLoaded() {
  try {
    return JSON.parse(
      localStorage.getItem(RESOURCE_LOADED)
    );
  } catch {
    return false;
  }
}

export function setLocalResourceLoaded(value) {
  localStorage.setItem(RESOURCE_LOADED, value)
}

export function getInterviewStatus() {
  try {
    return localStorage.getItem(INTERVIEW_STATUS);
  } catch {
    return null;
  }
}
